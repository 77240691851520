import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Router, Switch, Route, useParams } from "react-router-dom";
import { StartTestPage } from "./StartTestPage";
import { TestInstancePage } from "./TestInstancePage";

type WebCsnTestsDemoProps = {
    languages: any;
    student: any;
}

export const WebCsnTestsDemo = ({ ...props }: WebCsnTestsDemoProps) => {
    const params = useParams() as { category: string };
    const [redirect, setRedirect] = useState(window?.location?.href?.split("?")?.[1]?.split("=")?.[1] as string | undefined);

    useEffect(() => {
        if (redirect) {
            sessionStorage.setItem("web_redirect", redirect);
        } else {
            setRedirect(sessionStorage.getItem("web_redirect") ?? undefined);
        }
    }, [redirect]);

    return <Layout style={{ minHeight: "100vh", flexDirection: "row" }}>
        <Switch>
            <Route path="/web-test/test-instance/:instance">
                <TestInstancePage
                    isOpen={(value: boolean) => { }}
                    languages={props.languages}
                    student={props.student}
                    redirectURL={redirect}
                />
            </Route>
            <Route path="/web-test/:category/:language">
                <StartTestPage
                    isOpen={(value: boolean) => { }}
                    languages={props.languages}
                    student={props.student}
                    refreshBalance={() => { }}
                    redirectURL={redirect}
                />
            </Route>
        </Switch>
    </Layout>
}
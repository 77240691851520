import React, { useEffect, useState } from "react";
import { StartTestCard } from "./StartTestCard";
import { TestLayout } from "./TestLayout";
import { useHistory, useParams } from 'react-router-dom';

type StartTestPageProps = {
    isOpen: (state: boolean) => void;
    languages: any;
    student: any;
    refreshBalance: () => void;
    redirectURL?: string;
}

export const StartTestPage = ({ ...props }: StartTestPageProps) => {
    const history = useHistory();
    const [previewTime, setPreviewTime] = useState(30);
    const [exam, setExam] = useState(null as null | {
        questions: any[],
        ind: number,
    });
    const [isDemo, setIsDemo] = useState(true);
    const [isPremium, setIsPremium] = useState(false);
    const webTest = window.location.href.includes('web-test') && !sessionStorage.getItem('bearer_token');
    const { category, language } = useParams<{ category: string, language: string }>();

    useEffect(() => {
        if (props.redirectURL) {
            history.push(`/web-test/${category}/${language}`);
        }
    }, []);

    return <TestLayout
        isOpen={props.isOpen}
        languages={props.languages}
        previewTime={previewTime}
        exam={exam}
        getLanguage={(lang: string) => { }}
        goBackUrl={webTest ? "/" : (isDemo || (!isDemo && !isPremium)) ? "/planner" : "/tests"}
        redirectHandler={props.redirectURL ? () => { window.location.replace(props.redirectURL!) } : undefined}
    >
        <StartTestCard
            student={props.student?.hasOwnProperty("category_id") ? props.student : { category_id: category }}
            returnTime={(time: number) => setPreviewTime(time)}
            returnExam={(exam: any) => setExam(exam)}
            returnIsDemo={(isDemo: boolean) => setIsDemo(isDemo)}
            returnIsPremium={(premium: boolean) => setIsPremium(premium)}
            refreshBalance={props.refreshBalance}
        />
    </TestLayout>
}
import React, { useEffect, useState } from "react";
import SuccessAnimation from "../../img/gif/party_emoji_animated.gif";
import FailureAnimation from "../../img/gif/sad_emoji_animated.gif";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { timePassedFromTotal } from "../../helpers/timePassedFromTotal";
import { timePartIsSingularCheck } from "../../helpers/timePartIsSingularCheck";


type TheoryExamEndCardProps = {
    data?: {
        success?: boolean;
        score?: number;
        total?: number;
        timeRemaining?: string;
        successThreshold?: number;
        timeEnded?: boolean;
        timeLimit?: number;
    },
    isMobile?: boolean;
    setReview: (state: boolean) => void;
    redirectHandler?: () => void;
}


const SuccessMessage = ({ ...props }: { score: number, total: number, successThreshold: number, time: string }) => {
    const { t } = useTranslation();
    return <div>
        <Row justify="center" style={{ marginBottom: 20 }}>
            <img style={{ width: 96, height: 96 }} src={SuccessAnimation} alt="Test success animation" />
        </Row>
        <h1 className="heading-xl" style={{ color: "#218341", marginBottom: 8, textAlign: "center" }}>{t('csn_tests.test_succeed')}</h1>
    </div>
}

const FailureMessage = ({ ...props }: { score: number, total: number, successThreshold: number, time: string, reason: "time" | "fail" }) => {
    const { t } = useTranslation();
    return <div>
        <Row justify="center" style={{ marginBottom: 20 }}>
            <img style={{ width: 96, height: 96 }} src={FailureAnimation} alt="Test failure animation" />
        </Row>
        <h1 className="heading-xl" style={{ color: "#CC3326", marginBottom: 8, textAlign: "center" }}>{t('csn_tests.test_failed')}</h1>
    </div>
}

export const TestEndCard = ({ ...props }: TheoryExamEndCardProps) => {
    const { t } = useTranslation();
    const { data } = props;
    const history = useHistory();
    const [reason, setReason] = useState<"time" | "fail">("fail");
    const webTest = window.location.href.includes('web-test');

    useEffect(() => {
        setReason(data?.timeEnded ? "time" : "fail");
    }, [data])

    return <div className="shadow-card exam-end-card" style={{ maxWidth: 680, padding: 48 }}>
        {
            data === undefined
                ? <p>loading...</p>
                : ((data.score !== undefined
                    && data.success !== undefined
                    && data.successThreshold !== undefined
                    && data.timeEnded !== undefined
                    && data.timeRemaining !== undefined
                    && data.total !== undefined))
                    ? data.success
                        ? <SuccessMessage
                            score={data.score}
                            total={data.total}
                            successThreshold={data.successThreshold}
                            time={data.timeRemaining}
                        />
                        : <FailureMessage
                            score={data.score}
                            total={data.total}
                            successThreshold={data.successThreshold}
                            time={data.timeRemaining}
                            reason={reason}
                        />
                    : <></>
        }
        {
            reason === "time" && !data?.success
                ? <p className="bold-paragraph" style={{ marginBottom: 8, textAlign: "center" }}>{t('csn_tests.time_ended')}</p>
                : <>
                    <p className="paragraph" style={{ marginBottom: 8, textAlign: "center" }}>
                        {
                            t('csn_tests.test_completed_in', {
                                min: timePassedFromTotal(props.data?.timeRemaining, data?.timeLimit)?.minutes
                                    + `${timePartIsSingularCheck(timePassedFromTotal(props.data?.timeRemaining, data?.timeLimit)?.minutes)
                                        ? ' ' + t('csn_tests.in_minute')
                                        : ' ' + t('csn_tests.in_minutes')}`,
                                sec: timePassedFromTotal(props.data?.timeRemaining, data?.timeLimit)?.seconds
                                    + `${timePartIsSingularCheck(timePassedFromTotal(props.data?.timeRemaining, data?.timeLimit)?.seconds)
                                        ? ' ' + t('csn_tests.in_second')
                                        : ' ' + t('csn_tests.in_seconds')}`
                            })
                        }
                    </p>
                    <p className="paragraph" style={{ marginBottom: 32, textAlign: "center" }}>{t('csn_tests.to_succeed_you_need', { successThreshold: data?.successThreshold })}</p>
                    <Row style={{ marginBottom: 32 }}>
                        <Col span={24} sm={12}>
                            <div className="questions-count-wrap" style={{ marginRight: props.isMobile ? 0 : 12 }}>
                                <p>{t('e_learning.questions_count')}</p>
                                <b>{data?.total}</b>
                            </div>
                        </Col>
                        <Col span={24} sm={12}>
                            <div className="questions-count-wrap" style={{ marginLeft: props.isMobile ? 0 : 12 }}>
                                <p>{t('csn_tests.correct_answered')}</p>
                                <b style={{ color: data?.success ? "#218341" : "#CC3326" }}>{data?.score}</b>
                            </div>
                        </Col>
                    </Row>
                </>
        }
        {
            ((data?.total! - data?.score!) > 0 && reason === "fail")
            && <Button
                className="btn-review-csn-mistakes"
                style={{
                    marginBottom: 8,
                }}

                onClick={() => props.setReview(true)}
            >
                {t('csn_tests.review_mistakes')}
            </Button>
        }
        <Button
            type="primary"
            className={`btn-start-csn-test`}
            onClick={() => {
                if (props.redirectHandler) {
                    props.redirectHandler();
                    return;
                }
                history.goBack()
            }}
        // disabled={loading}
        // loading={loading}
        >
            {webTest ? t('csn_tests.buy_subscription') : t('csn_tests.start_test_again')}
        </Button>
    </div>
}
import { Button, Card, Input, Row, Space, Table, Modal, message, Col, DatePicker, Checkbox, Select, Radio } from "antd";
import { CarOutlined, UserOutlined, CalendarOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";

export type School = {
    [key: string]: string | null;
    name: string | null;
    registration_nr: string | null;
    legal_address: string | null;
    bank: string | null;
    swift: string | null;
    bank_account: string | null;
}

function renderLessonInfo(data: any, record: any) {
    const hasLesson = data.student_lesson;
    const instructor_service = hasLesson ? data.student_lesson.lesson_time_slot.instructor_service : {};
    const instructor = hasLesson ? instructor_service.instructor : {};

    return <Space size={0} style={{ fontSize: 13 }} direction="vertical">
        {!hasLesson || record.commision ? <span style={{ whiteSpace: "normal" }}>{data.reason}</span> : <>
            <Space>
                <span style={{ fontWeight: "bold" }}>({instructor_service.category.value} kat.)</span>

                <CarOutlined />
                <span>{instructor_service.vehicle}; {instructor_service.licence_plate}</span>

                <UserOutlined />
                <span>{instructor.first_name} {instructor.last_name}</span>
            </Space>
            <Space>
                <CalendarOutlined />
                <span>{data.student_lesson.lesson_time_slot.date}</span>
                <ClockCircleOutlined />
                <span>{data.student_lesson.lesson_time_slot.time_from} - {data.student_lesson.lesson_time_slot.time_to}</span>
            </Space>
            {
                data.student_lesson.status.includes("cancelled")
                && <Space>
                    <b style={{ color: "red" }}>
                        {
                            data.student_lesson.status.includes("student")
                                ? "Atcēla Students"
                                : "Atcēla Instruktors"
                        }
                    </b>
                </Space>
            }
        </>
        }
    </Space>;
};

export const StudentPaymentsPDFTable = (props: any) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imgBase64Service, setImgBase64Service] = useState(undefined);
    const [imgBase64Driving, setImgBase64Driving] = useState(undefined);
    const [services, setServices] = useState([] as any);
    const [self, setSelf] = useState({} as any);
    const [promoAmount, setPromoAmount] = useState(null as any);
    const [promoDeduction, setPromoDeduction] = useState([] as any);
    const [reqModalVisible, setReqModalVisible] = useState(false);
    const [customRequisites, setCustomRequisites] = useState("");
    const [school, setSchool] = useState<School>({
        name: null,
        registration_nr: null,
        legal_address: null,
        bank: null,
        swift: null,
        bank_account: null
    });
    const [period, setPeriod] = useState(null as any);
    const [creditMode, setCreditMode] = useState(undefined as any);
    const [allServicesBonus, setAllServicesBonus] = useState([] as any);

    const toDataURL = (url: any, callback: any) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const getSelf = async () => {
        await http.get('/self').then((response: any) => {
            //console.log(response.data.data)
            setSelf(response.data.data)
        })
    }

    const invoiceSettings = (data: any, invoiceType: "driving" | "service", defaultSettings: any, promoAmount: number = 0, collectiveReq: string = customRequisites) => {
        const settings = {
            ...defaultSettings,
            type: "person" as "legal" | "person",
            hidePerson: false,
            showInstructorBankAcc: true,
        }
        Modal.confirm({
            title: "Rēķina uzstādījumi",
            content: <div>
                {
                    invoiceType === "driving"
                    && <>
                        <p style={{ marginBottom: 5 }}>Rēķina veids</p>
                        <Select
                            defaultValue={settings.type}
                            style={{ width: 200, marginBottom: 8 }}
                            onChange={(e: "legal" | "person") => {
                                settings.type = e;
                            }}
                        >
                            <Select.Option value="legal">Juridiskai personai</Select.Option>
                            <Select.Option value="person">Fiziskai personai</Select.Option>
                        </Select>
                    </>
                }
                {/* <p style={{ marginRight: 5, display: "inline-block" }}>Paslēpt apmācāmās personas datus</p>
                <Checkbox
                    type="checkbox"
                    onChange={(e) => {
                        settings.hidePerson = e.target.checked;
                    }}
                /> */}
                {/* {
                    invoiceType === "driving"
                    && <>
                        <p style={{ marginRight: 5, display: "inline-block" }}>Rādīt instruktora bankas kontu</p>
                        <Checkbox
                            defaultChecked={settings.showInstructorBankAcc}
                            type="checkbox"
                            onChange={(e) => {
                                settings.showInstructorBankAcc = e.target.checked;
                            }}
                        />
                    </>
                } */}
            </div>,
            onOk: () => {
                createPdf(data, invoiceType, settings, promoAmount, collectiveReq,);
            }
        });
    }

    const bulkInvoiceWarning = (data: any, promoAmount: number = 0, collectiveReq: string = customRequisites) => {
        const settings = {
            type: "person" as "legal" | "person",
            hidePerson: false,
            showInstructorBankAcc: true,
        }
        Modal.confirm({
            title: "Rēķina uzstādījumi",
            content: <div>
                <p style={{ marginBottom: 5 }}>Rēķina veids</p>
                <Select
                    defaultValue={settings.type}
                    style={{ width: 200, marginBottom: 8 }}
                    onChange={(e: "legal" | "person") => {
                        settings.type = e;
                    }}
                >
                    <Select.Option value="legal">Juridiskai personai</Select.Option>
                    <Select.Option value="person">Fiziskai personai</Select.Option>
                </Select>
                <p style={{ marginRight: 5, display: "inline-block" }}>Paslēpt apmācāmās personas datus</p>
                <Checkbox
                    type="checkbox"
                    onChange={(e) => {
                        settings.hidePerson = e.target.checked;
                    }}
                />
                <p style={{ marginRight: 5, display: "inline-block" }}>Rādīt instruktora bankas kontu</p>
                <Checkbox
                    defaultChecked={settings.showInstructorBankAcc}
                    type="checkbox"
                    onChange={(e) => {
                        settings.showInstructorBankAcc = e.target.checked;
                    }}
                />
            </div>,
            onOk: () => {
                createPdf(data, "driving", settings, promoAmount, collectiveReq,);
            }
        });
    }

    const createPdf = (data: any, type: "driving" | "service", settings: any, promoAmount: number = 0, collectiveReq: string = customRequisites) => {
        let opt = {
            margin: 1,
            filename: 'rekins.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
        };

        http.post('/get-pdf-result', { html: pdfTpl(school, data, type, settings, promoAmount, collectiveReq) }, { responseType: 'blob' }).then((response: any) => {
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            const fileName = type === "driving"
                ? `${moment(data.student_lesson?.lesson_time_slot.date).format("DD.MM.YYYY")}_${data.id}_${data.student_lesson?.student?.first_name}${data.student_lesson?.student.last_name}`
                : Array.isArray(data)
                    ? `${moment(data[0].created_at).format("DD.MM.YYYY")}_${data[0].id}_${data[0]?.self_info?.first_name}${data[0]?.self_info?.last_name}`
                    : `${moment(data.created_at).format("DD.MM.YYYY")}_${data.id}_${data?.self_info?.first_name}${data?.self_info?.last_name}`
            link.href = url;
            link.setAttribute('download', `${fileName}.pdf`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        });
    }

    const pdfTpl = (school: School, data: any, type: "driving" | "service", settings: any, promoAmount: number = 0, collectiveReq: string = "") => {
        if (type === "driving") {
            const invoiceType = settings.type;
            const hidePerson = settings.hidePerson;
            const showInstructorBankAcc = true;
            const lesson = data.student_lesson;
            const slot = lesson?.lesson_time_slot;
            const prices = slot?.lesson_time_slot_prices;
            const how_many = slot?.how_many_lessons == 5 || slot?.how_many_lessons == 6 ? 1 : slot?.how_many_lessons;
            const service = slot?.instructor_service;
            const instructor_price = prices?.filter((price: any) => price.position === "Instruktors")[0];
            const school_prices = prices?.filter((price: any) => price.position !== "Instruktors");
            const self = data.self_info;
            const ladderAmount = data.ladder?.amount ? Number(data.ladder?.amount) * -1 : 0;
            const bonus = data.studentBonus ? Number(data.studentBonus.amount) + ladderAmount : 0 + ladderAmount;
            // const bonus = data.studentBonus ? Number(data.studentBonus.amount) : 0;
            const totalInstructor = Number(instructor_price?.rate) * how_many;
            const totalInstructorWithVat = totalInstructor * (1 + (Number(instructor_price?.rate_vat)) / 100);
            const totalInstructorVat = (totalInstructorWithVat) - (totalInstructor);

            let totalSchool = school_prices?.reduce((prev: any, current: any, index: number) => {
                return prev + Number(current.rate) * how_many
            }, 0);
            let totalSchoolWithVat = school_prices?.reduce((prev: any, current: any) => {
                return prev + Number(current.rate) * how_many * (1 + (current.rate_vat / 100))
            }, 0);
            let totalSchoolVat = totalSchoolWithVat - totalSchool;
            const schoolVat = school_prices[0].rate_vat;
            const totalOveral = totalInstructorWithVat + totalSchoolWithVat;
            const difference = ((Number(data.amount) - bonus) * -1) - totalOveral;
            const differenceWithoutVat = difference / (1 + (schoolVat / 100));
            totalSchool = school_prices?.reduce((prev: any, current: any, index: number) => {
                return prev + Number(current.rate) * how_many
            }, 0) + differenceWithoutVat;
            totalSchoolWithVat = school_prices?.reduce((prev: any, current: any, index: number) => {
                return prev + Number(current.rate) * how_many * (1 + (Number(current.rate_vat) / 100))
            }, 0) + difference;
            totalSchoolVat = totalSchoolWithVat - totalSchool;
            // totalSchoolVat += difference;
            // totalSchoolWithVat += difference;

            // const difference = Number((Math.abs(Number(data.amount))
            //     - (school_prices?.reduce((prev: any, p: any) => {
            //         return prev + Number(((p.rate * how_many) * (1 + p.rate_vat / 100)).toFixed(2))
            //     }, 0) + Number(((instructor_price?.rate * how_many) * (1 + instructor_price?.rate_vat / 100)).toFixed(2)))).toFixed(2));
            // console.log(difference)
            let instructor_requisites = service?.instructor?.payment_requisites;
            if (instructor_requisites !== null && instructor_requisites !== undefined) {
                instructor_requisites = instructor_requisites.replaceAll('\n', '<br />')
                if (showInstructorBankAcc !== undefined) {
                    if (!showInstructorBankAcc) {
                        instructor_requisites = instructor_requisites
                            .split("<br />")
                            .filter((item: any) => !item.includes("Kont"))
                            .join("<br />")
                    }
                }
            }

            let student_requisites = self?.requisites;
            if (student_requisites !== null && student_requisites !== undefined) {
                student_requisites = student_requisites.replaceAll('\n', '<br />')
            }
            if (data.payment_requisites !== null) student_requisites = data.payment_requisites.replaceAll('\n', '<br />');

            const drivingInvoice = `
        <html>
          <head>
            <style>
              .table { width: 100%; border-collapse: collapse; }
              .table th { color: white; background: #888; }
              .table th.sub { background: #bbb; color: black; }
              .table td, .table th { border: 1px solid #888; padding: 3px; font-size: 10px }
            </style>
          </head>
          <body>
          <img width="119" height="37" src="${imgBase64Driving}" />
          <p style="text-align: right">Datums: ${moment().format("DD/MM/YYYY")}</p>
          <table class="table" style="margin-bottom: 2px">
          <tr>
            <th colspan="2" class="sub">Maksājuma&nbsp;saņēmējs</th>
          </tr>
          <tr>
            <td colspan="2">${school.name}</td>
          </tr>
          <tr>
            <td colspan="2">Vienotais&nbsp;reģistrācijas&nbsp;Nr.&nbsp;${school.registration_nr}</td>
          </tr>
          <tr>
            <td colspan="2">PVN reģistrācijas Nr.&nbsp;LV${school.registration_nr}</td>
          </tr>
          <tr>
            <td colspan="2">Juridiskā adrese&nbsp;${school.legal_address}</td>
          </tr>
          <tr>
            <td width="50%">Bankas&nbsp;rekvizīti:</td>
            <td width="50%">
            Banka:&nbsp;${school.bank}<br />
            Konta&nbsp;Nr.:&nbsp;${school.bank_account}<br />
            SWIFT:&nbsp;${school.swift}
            </td>
          </tr>
        </table>
            <br />
      <table class="table" style="margin-bottom: 2px">
              <tr>
                <th colspan="2" class="sub">Pakalpojuma&nbsp;saņēmējs</th>
              </tr>
              ${invoiceType === "legal"
                    ? student_requisites !== null && student_requisites !== undefined
                        ? `<tr>
            <td width="50%">Rekvizīti:</td>
            <td width="50%">
            ${student_requisites
                        }
            </td>
          </tr>`
                        : `<tr>
                        <td width="50%">Vārds&nbsp;uzvārds:</td>
                        <td width="50%">
                        ${data.student_lesson?.student?.first_name.replace(" ", "&nbsp;")}&nbsp;${data.student_lesson?.student.last_name.replace(" ", "&nbsp;")}
                        </td>
                      </tr>
                      <tr>
                      <td width="50%">Personas&nbsp;kods:</td>
                      <td width="50%">${data.student_lesson?.student.personal_identity_number}</td>
                    </tr>`
                    : `<tr>
            <td width="50%">Vārds&nbsp;uzvārds:</td>
            <td width="50%">
            ${data.student_lesson?.student?.first_name.replace(" ", "&nbsp;")}&nbsp;${data.student_lesson?.student.last_name.replace(" ", "&nbsp;")}
            </td>
          </tr>
          <tr>
          <td width="50%">Personas&nbsp;kods:</td>
          <td width="50%">${data.student_lesson?.student.personal_identity_number}</td>
        </tr>
          `
                }
              ${{/*student_requisites === null || student_requisites === undefined
                    ? `<tr>
                <td>Vārds&nbsp;uzvārds:</td>
                <td>
                ${data.student_lesson?.student?.first_name.replace(" ", "&nbsp;")}&nbsp;${data.student_lesson?.student.last_name.replace(" ", "&nbsp;")}
                </td>
              </tr>`
                    : ``
                }
              ${student_requisites !== null && student_requisites !== undefined
                    ? `<tr>
                <td>Rekvizīti:</td>
                <td>
                ${student_requisites
                    }
                </td>
              </tr>`
                    : `<tr>
              <td>Personas&nbsp;kods:</td>
              <td>${data.student_lesson?.student.personal_identity_number}</td>
            </tr>`
                */}}
            </table>
            <h1 style="font-weight: bold; text-align: center; font-size: 13px;">RĒĶINS&nbsp;Nr.&nbsp;${data.student_lesson?.lesson_time_slot.date}/${data.id}</h1>
            <table class="table" style="margin-bottom: 2px">
                ${invoiceType === "legal" && !hidePerson
                    ? `<tr>
                    <td width="50%">Apmācāmā persona:</td>
                    <td width="50%">
                        ${data.student_lesson?.student?.first_name.replace(" ", "&nbsp;")}&nbsp;${data.student_lesson?.student.last_name.replace(" ", "&nbsp;")}, p.k.${data.student_lesson?.student.personal_identity_number}
                    </td>
                </tr>`
                    : ''
                }
              <tr>
                <th colspan="2" class="sub">Pakalpojuma&nbsp;sniedzējs</th>
              </tr>
              <tr>
                <td colspan="2"><strong>Instruktors</strong></td>
              </tr>
              
                <tr>
                  <td width="50%">Rekvizīti:</td>
                  <td width="50%">
                  ${instructor_requisites !== null && instructor_requisites !== undefined
                    ? instructor_requisites
                    : `${data.student_lesson?.lesson_time_slot?.instructor_service.instructor.first_name.replace(" ", "&nbsp;")}&nbsp;${data.student_lesson?.lesson_time_slot?.instructor_service.instructor.last_name.replace(" ", "&nbsp;")}`
                }
                  </td>
                </tr>
              <tr>
                <td colspan="2">
                  <table class="table" style="margin-bottom: 4px">
                    <tr>
                      <th colspan="5" class="sub">Pakalpojumi</th>
                    </tr>
                    <tr>
                    <td width="50%" style="text-align: center">Nosaukums</td>
                    <td style="text-align: center">Mērvienība</td>
                    <td style="text-align: center">Skaits</td>
                    <td style="text-align: center">Cena</td>
                    <td style="text-align: center">Summa, EUR</td>
                    </tr>
                    <tr>
                      <td>
                        Braukšanas&nbsp;nodarbība:&nbsp;${service?.category.value.replace(" ", "&nbsp;")}&nbsp;kategorija
                      </td>
                      <td style="text-align: center">stunda</td>
                      <td style="text-align: center">${how_many}</td>
                      <td style="text-align: center">${instructor_price?.rate}</td>
                      <td style="text-align: right">${totalInstructor?.toFixed(2)}</td>
                    </tr>
                  </table>
                  <table class="table">
                    <tr>
                    <td style="text-align: right">Summa bez PVN</td>
                    <td style="text-align: right">${totalInstructor?.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style="text-align: right">PVN (likme) ${Number(instructor_price?.rate_vat)?.toFixed(0)}%</td>
                    <td style="text-align: right">${totalInstructorVat?.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style="text-align: right">Summa kopā</td>
                    <td style="text-align: right">${totalInstructorWithVat?.toFixed(2)}</td>
                    </tr>
                  </table>
                </td>  
              </tr>
            </table>
            <br />
            <table class="table" style="margin-bottom: 2px">
              <tr>
                <th colspan="2" class="sub">Pakalpojuma&nbsp;nodrošinātājs</th>
              </tr>
              <tr>
            <td colspan="2">${school.name}</td>
          </tr>
          <tr>
            <td colspan="2">Vienotais&nbsp;reģistrācijas&nbsp;Nr.&nbsp;${school.registration_nr}</td>
          </tr>
          <tr>
            <td colspan="2">PVN reģistrācijas Nr.&nbsp;LV${school.registration_nr}</td>
          </tr>
          <tr>
            <td colspan="2">Juridiskā adrese&nbsp;${school.legal_address}</td>
          </tr>
              <tr>
                <td colspan="2">
                  <table class="table" style="margin-bottom: 4px">
                    <tr>
                      <th colspan="5" class="sub">Pakalpojumi</th>
                    </tr>
                    <tr>
                    <td width="50%" style="text-align: center">Nosaukums</td>
                    <td style="text-align: center">Mērvienība</td>
                    <td style="text-align: center">Skaits</td>
                    <td style="text-align: center">Cena</td>
                    <td style="text-align: center">Summa, EUR</td>
                    </tr>
                    ${school_prices?.map((p: any, index: any) => `
                      <tr>
                        <td>${p.position.replace(" ", "&nbsp;")}</td>
                        <td style="text-align: center">stunda</td>
                        <td style="text-align: center">${how_many}</td>
                        ${index === 0
                        ? `<td style="text-align: center">${(Number(p.rate) + (differenceWithoutVat / how_many)) > 0 ? (Number(p.rate) + (differenceWithoutVat / how_many)).toFixed(5) : "0.00"}</td>`
                        : `<td style="text-align: center">${p.rate}</td>`
                    }
                        ${index === 0
                        ? `<td style="text-align: center">${(Number(p.rate) * how_many + differenceWithoutVat) > 0 ? (Number(p.rate) * how_many + differenceWithoutVat)?.toFixed(5) : "0.00"}</td>`
                        : `<td style="text-align: center">${(Number(p.rate) * how_many)?.toFixed(2)}</td>`
                    }
                      </tr>
                    `).join(" ")}
                  </table>
                  <table class="table">
                  ${/*bonus !== 0
                    ? `<tr>
                    <td style="text-align: right">Atlaide</td>
                    <td style="text-align: right">${Math.abs(bonus).toFixed(2)}</td>
                    </tr>`
                    : ''
                */''}
                    <tr>
                    <td style="text-align: right">Summa bez PVN</td>
                    <td style="text-align: right">${totalSchool?.toFixed(5)}</td>
                    </tr>
                    <tr>
                    <td style="text-align: right">PVN (likme) 21%</td>
                    <td style="text-align: right">${totalSchoolVat?.toFixed(5)}</td>
                    </tr>
                    <tr>
                    <td style="text-align: right">Summa kopā</td>
                    <td style="text-align: right">${Number(totalSchoolWithVat.toFixed(5))}</td>
                    </tr>
                  </table>
                </td>  
              </tr>
            </table>
            <br />
            <table class="table" style="margin-bottom: 0px">
            <tr>
            <th class="sub" style="text-align: right">No depozīta apmaksātā summa, EUR</th>
          </tr>
          <tr>
            <td style="text-align: right">${!!data.credit ? "0.00" : ((Number(data.amount) - bonus) * -1).toFixed(2)}</td>
          </tr>
              <tr>
                <th class="sub" style="text-align: right">Summa&nbsp;apmaksai, EUR</th>
              </tr>
              <tr>
                <td style="text-align: right">${!!data.credit ? ((Number(data.amount) - bonus) * -1).toFixed(2) : "0.00"}</td>
              </tr>
            </table>
            <i>Rēķins sagatavots elektroniski un derīgs bez paraksta</i>
          </body>
        </html>
      `
            return drivingInvoice;
        } else {
            const creditMode = settings.creditMode;
            const hidePerson = settings.hidePerson;
            if (creditMode !== undefined) {
                if (creditMode) {
                    data = data.filter((item: any) => !!item.credit)
                } else {
                    data = data.filter((item: any) => !!!item.credit)
                }
            }
            let fullPriceWithVat: any;
            let fullPrice: any;
            let priceWithVat: any;
            let price: any;
            let vat = 1;
            let vatIncluded;
            let promo = 0;
            let toBePaid = "0.00";
            if (!Array.isArray(data)) {
                const isRegistration = (data.reason?.includes("Registration") || !!data?.individual_driving_registration) ?? false
                const promoAffected = Object.keys(data.promo_effect).length > 0
                if (!promoAffected) promo = 0;
                else {
                    promo = isRegistration
                        ? data.promo_effect?.registration?.discount
                        : data.promo_effect?.services?.[data.study_group_service_id]?.discount
                }
                vat = vatChecker(data, isRegistration || !!data?.individual_driving_registration);
                fullPriceWithVat = (Number(data.amount) - ((data.studentBonus && !!!data.promo) ? Number(data.studentBonus.amount) : 0)) * -1;
                priceWithVat = fullPriceWithVat - (promoAmount > 0 ? promoAmount : Number(promo));
                price = priceWithVat / vat;
                fullPrice = fullPriceWithVat / vat;
                toBePaid = !!data.credit
                    ? priceWithVat?.toFixed(2)//Math.abs(Number(data.amount)).toFixed(2)
                    : "0.00"
            } else {
                vatIncluded = data
                    .map((item: any) => {
                        const serviceVat = vatChecker(item, item?.reason?.includes("Registration") || !!item?.individual_driving_registration)
                        return serviceVat > 1
                            ? serviceVat
                            : undefined
                    })
                    .filter((item: any) => item !== undefined)
                    .filter((item: any, index: any, self: any) => {
                        return self.indexOf(item) === index;
                    })
                toBePaid = data.reduce((prev: any, current: any) => {
                    return !!current.credit
                        ? (Number(prev) + Math.abs(Number(current.amount))).toFixed(2)
                        : Number(prev)
                }, "0.00");
                promo = data.reduce((prev: any, current: any) => {
                    return prev + promoResolver(current)
                }, 0)
            }
            let requisites = Array.isArray(data)
                ? collectiveReq === ""
                    ? data[0]?.self_info?.requisites
                    : collectiveReq
                : data.payment_requisites !== null
                    ? data.payment_requisites
                    : data.self_info.requisites
            if (requisites !== null) {
                requisites = requisites.replaceAll(`\n`, "<br />")
            }

            const endSum = (includePromo: boolean = false) => {
                const withoutDeduction = data.reduce((prevItem: any, item: any) => {
                    let priceWithVat;
                    let vat;
                    let price;
                    priceWithVat = (Number(item.amount) - ((item.studentBonus && !!!item.promo) ? Number(item.studentBonus.amount) : 0)) * -1;
                    vat = vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration)
                    price = priceWithVat / vat;
                    return (Number(prevItem) + priceWithVat).toFixed(2);
                }, 0);
                const endSum = withoutDeduction - (includePromo ? Number(promo) : 0);
                return endSum.toFixed(2);
            }

            const serviceInvoice = `
                <table class="date-table">
                    <tbody>
                        <tr>
                            <td style="padding-right: 50px">
                                <p class="big-bold" style="font-weight: bold">Rēķins Nr.</p>
                            </td>
                            <td style="text-align: left;">
                                <p class="big-bold">${Array.isArray(data) ? data[0].id : data.id}</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-right: 50px">
                                <p style="font-size: 14px">Datums</p>
                            </td>
                            <td style="text-align: left;">
                                <p style="font-size: 14px">${Array.isArray(data) ? data[0].ts.split(" ")[0] : data.ts.split(" ")[0]}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <img src="${imgBase64Service}" style="height: 80px; margin-left: 150px" />
            
            <table style="width: 100%; margin: 50px 0;">
                <tbody>
                    <tr>
                        <td style="width: 50%; vertical-align:top">
                        <table class="req-table">
                        <tbody>
                            <tr>
                                <td style="width: 10%">
                                    <b>Maksātājs</b>
                                </td>
                                <td style="width: 15%">
                                ${requisites === null
                    ? `<p class="big-bold">${Array.isArray(data) ? data[0].self_info.first_name + " " + data[0].self_info.last_name : data.self_info.first_name + " " + data.self_info.last_name}</p>`
                    : ``
                }
                                </td>
                            </tr>
                            ${requisites === null
                    ? `<tr>
                                        <td>
                                            <p>Personas kods</p>
                                        </td>
                                        <td>
                                            <p>${Array.isArray(data) ? data[0].self_info.personal_identity_number : data.self_info.personal_identity_number}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Deklarētā adrese</p>
                                        </td>
                                        <td>
                                            <p>${Array.isArray(data) ? data[0].self_info.declared_address : data.self_info.declared_address}</p>
                                        </td>
                                    </tr>`
                    : `<tr>
                                        <td colspan="2">
                                        ${requisites}
                                        <br />
                                        ${hidePerson
                        ? ``
                        : `<p>Apmācāmā persona: ${Array.isArray(data) ? data[0].self_info.first_name + " " + data[0].self_info.last_name : data.self_info.first_name + " " + data.self_info.last_name}</p>`
                    }
                                        </td>
                                    </tr>
                                    `
                }
                            
                        </tbody>
                    </table>
                        </td>
                        <td style="width: 50%; vertical-align:top">
                        <table class="req-table">
                        <tbody>
                            <tr>
                                <td style="width: 10%">
                                    <strong>Nosūtītājs</strong>
                                </td>
                                <td style="width: 25%">
                                    <p class="big-bold">${school.name}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Reģ. Nr.</p>
                                </td>
                                <td>
                                    <p>${school.registration_nr}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>PVN Nr.</p>
                                </td>
                                <td>
                                    <p>LV${school.registration_nr}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Juridiskā adrese</p>
                                </td>
                                <td>
                                    <p>${school.legal_address}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Banka</p>
                                </td>
                                <td>
                                    <p>${school.bank}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>SWIFT</p>
                                </td>
                                <td>
                                    <p>${school.swift}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Konts</p>
                                </td>
                                <td>
                                    <p>${school.bank_account}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="service-table" style="width: 100%">
                <thead style="background-color: #dddddd; border: 1px solid black">
                    <tr>
                        <th style="text-align: left">Apraksts</th>
                        <th style="text-align: center">Skaits</th>
                        <th style="text-align: center">Vienības</th>
                        <th style="text-align: right">Cena</th>
                        <th style="text-align: right; width: 40px">PVN</th>
                        <th style="text-align: right;">Summa, EUR</th>
                    </tr>
                </thead>
                ${Array.isArray(data)
                    ? data.map((item: any) => {
                        let priceWithVat;
                        let price;
                        let vat;
                        priceWithVat = (Number(item.amount) - ((item.studentBonus && !!!item.promo) ? Number(item.studentBonus.amount) : 0)) * -1;
                        vat = vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration)
                        price = priceWithVat / vat;
                        return (`<tbody class="data-table">
                        <tr>
                            <td style="text-align: left">${item.study_service !== null
                                ? item.study_service.value_lv
                                : !!item.commision
                                    || item.extra_pmp !== null
                                    || item.extra_medical !== null
                                    || item.extra_omniva !== null
                                    || item.theory_exam_slot_id !== null
                                    || item.theory_lesson !== null
                                    ? item.reason
                                    : item.student_csdd_test_subscription_id
                                        ? "CSN testa abonements"
                                        : "Reģistrācija"}</td>
                            <td class="centered">1</td>
                            <td class="centered">pak.</td>
                            <td>${price?.toFixed(2)}</td>
                            <td>${((vat - 1) * 100).toFixed(0)} %</td>
                            <td>${priceWithVat?.toFixed(2)}</td>
                        </tr>
                    </tbody>`)
                    })
                    : `<tbody class="data-table">
                    <tr>
                        <td style="text-align: left">${data.study_service !== null
                        ? data.study_service.value_lv
                        : !!data.commision
                            || data.extra_pmp !== null
                            || data.extra_medical !== null
                            || data.extra_omniva
                            || data.theory_exam_slot_id !== null
                            || data.theory_lesson !== null
                            ? data.reason
                            : data.student_csdd_test_subscription_id
                                ? "CSN testa abonements"
                                : "Reģistrācija"}</td>
                        <td class="centered">1</td>
                        <td class="centered">pak.</td>
                        <td>${fullPrice?.toFixed(2)}</td>
                        <td>${((vat - 1) * 100).toFixed(0)} %</td>
                        <td>${fullPriceWithVat?.toFixed(2)}</td>
                    </tr>
                </tbody>`
                }
                <tbody>
                <tr>
                        <td colspan="5"><b>Kopā</b></td>
                        <td style="border: 1px solid black">${Array.isArray(data)
                    ? endSum()
                    : (Number(priceWithVat) + Number(promo)).toFixed(2)
                }</td>
                    </tr>
                ${Array.isArray(data)
                    ? Number(promo) > 0
                        ? `<tr>
                            <td colspan="5"><b>Piemērota atlaide</b></td>
                            <td style="border: 1px solid black">${promo.toFixed(2)}</td>
                        </tr>`
                        : ``
                    : Number(promo) > 0
                        ? `<tr>
                    <td colspan="5"><b>Piemērota atlaide</b></td>
                    <td style="border: 1px solid black">${promo}</td>
                </tr>`
                        : `Piemērota atlaide ${promo}`
                }
                ${promo > 0
                    ? `<tr>
                        <td colspan="5"><b>Kopā ar atlaidi</b></td>
                        <td style="border: 1px solid black">${Array.isArray(data)
                        ? data.reduce((prevItem: any, item: any) => {
                            let priceWithVat;
                            let vat;
                            let price;
                            const promo = promoResolver(item);
                            // const relatedPromo = promoDeduction.find((promoObj: any) => {
                            //     return (item.study_group_service_id && item.study_group_service_id === promoObj.study_group_service_id)
                            //         || (promoObj.payment_method === "promo_promo_code_percentage_registration" && item.reason.includes("Registration"))
                            // });
                            // if (relatedPromo !== undefined) {
                            //     promo = Number(relatedPromo.amount)
                            // }
                            priceWithVat = (Number(item.amount) - ((item.studentBonus && !!!data.promo) ? Number(item.studentBonus.amount) : 0)) * -1 - promo;
                            vat = vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration)
                            price = priceWithVat / vat;
                            return (Number(prevItem) + priceWithVat).toFixed(2);
                        }, 0)
                        : priceWithVat?.toFixed(2)
                    }</td>
                </tr>`
                    : ``}
                
                    ${Array.isArray(data)
                    ? vatIncluded?.map((vatRate: any) => {
                        return `<tr>
                            <td colspan="5">Ar PVN ${Math.round((vatRate - 1) * 100).toFixed(0)}% apliekamā summa</td>
                            <td style="border: 1px solid black">${data.filter((item: any) => {
                            return vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration) === vatRate
                        }).reduce((prevItem: any, item: any) => {
                            console.log(item)
                            let priceWithVat;
                            let vat;
                            let price;
                            const promo = promoResolver(item);
                            // const relatedPromo =
                            //     promoAmount > 0 || Number(promo) > 0
                            //         ? promoDeduction.find((promoObj: any) => {
                            //             return (item.study_group_service_id && item.study_group_service_id === promoObj.study_group_service_id)
                            //                 || (promoObj.payment_method === "promo_promo_code_percentage_registration" && item.reason.includes("Registration"))
                            //         })
                            //         : undefined
                            // if (relatedPromo !== undefined) {
                            //     promo = Number(relatedPromo.amount)
                            // }
                            priceWithVat = (Number(item.amount) - ((item.studentBonus && !!!item.promo) ? Number(item.studentBonus.amount) : 0)) * -1 - promo;
                            vat = vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration)
                            price = priceWithVat / vat;
                            return (Number(prevItem) + price).toFixed(2);
                        }, 0)
                            }</td>
                        </tr>
                        <tr>
                        <td colspan="5">PVN ${Math.round((vatRate - 1) * 100).toFixed(0)}%</td>
                        <td style="border: 1px solid black">${data.filter((item: any) => {
                                return vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration) === vatRate
                            }).reduce((prevItem: any, item: any) => {
                                let priceWithVat;
                                let vat;
                                let price;
                                const promo = promoResolver(item);
                                // const relatedPromo =
                                //     promoAmount > 0 || Number(promo) > 0
                                //         ? promoDeduction.find((promoObj: any) => {
                                //             return (item.study_group_service_id && item.study_group_service_id === promoObj.study_group_service_id)
                                //                 || (promoObj.payment_method === "promo_promo_code_percentage_registration" && item.reason.includes("Registration"))
                                //         })
                                //         : undefined
                                // if (relatedPromo !== undefined) {
                                //     promo = Number(relatedPromo.amount)
                                // }
                                priceWithVat = (Number(item.amount) - ((item.studentBonus && !!!item.promo) ? Number(item.studentBonus.amount) : 0)) * -1 - promo;
                                vat = vatChecker(item, item.reason?.includes("Registration") || !!item?.individual_driving_registration)
                                price = priceWithVat / vat;
                                return (Number(prevItem) + (priceWithVat - price)).toFixed(2);
                            }, 0)
                            }</td>
                </tr>`
                    })
                    : vat > 1
                    && `<tr>
                    <td colspan="5">Ar PVN ${Math.round((vat - 1) * 100).toFixed(0)}% apliekamā summa</td>
                    <td style="border: 1px solid black">${price?.toFixed(2)}
                </td>
                </tr>
                <tr>
                        <td colspan="5">PVN ${Math.round((vat - 1) * 100).toFixed(0)}%</td>
                        <td style="border: 1px solid black">${(priceWithVat - price).toFixed(2)
                    }</td>
                        </tr>`
                }
                <tr>
                        <td colspan="5"><b>No depozīta apmaksātā summa</b></td>
                        <td style="border: 1px solid black">${Array.isArray(data)
                    ? !!data[0].credit ? "0.00" : endSum(true)
                    : !!data.credit ? "0.00" : (priceWithVat).toFixed(2)
                }</td>
                    </tr>
                    <tr>
                        <td colspan="5"><b>Summa apmaksai</b></td>
                        <td style="border: 1px solid black">
                        ${Array.isArray(data)
                    ? !!data[0].credit ? endSum(true) : "0.00"
                    : !!data.credit ? (priceWithVat).toFixed(2) : "0.00"
                }
                        </td>
                    </tr>
                </tbody>
            </table>
            <b style="display: block; margin-top: 50px">Rēķins sagatavots elektroniski un ir derīgs bez paraksta.</b>
          `
            return serviceInvoice;
        }
    };

    const vatChecker = (data: any, isRegistration: boolean) => {
        const service = data.extra_pmp !== null
            ? data.extra_pmp.study_group_service
            : data.extra_medical !== null
                ? data.extra_medical.study_group_service
                : data.theory_lesson !== null
                    ? data.theory_lesson
                    : data.extra_omniva !== null
                        ? data.extra_omniva.study_group_service
                        : data.study_service
        const vat = isRegistration && !!!data?.individual_driving_registration
            ? Number(data.study_group.registration_vat) / 100 + 1
            : !!data.commision || data.theory_exam_slot_id !== null || data.theory_lesson !== null || !!data?.individual_driving_registration || data.student_csdd_test_subscription_id
                ? 21 / 100 + 1
                : service !== null
                    ? Number(service.vat) / 100 + 1
                    : 1
        return vat
    }

    const promoResolver = (item: any) => {
        if (!!item.promo && item.bonus_payment_id) return 0
        const relatedPromo = promoDeduction.find((promoObj: any) => {
            return (item.study_group_service_id && item.study_group_service_id === promoObj.study_group_service_id)
                || (promoObj.payment_method?.includes("registration") && item.reason?.includes("Registration"))
        })
        if (relatedPromo !== undefined) {
            return Number(relatedPromo.amount)
        }
        return 0
    }

    async function loadPayments() {
        let params = undefined as any;
        if (period) {
            params = {
                from: moment(period[0]).format("YYYY-MM-DD"),
                to: moment(period[1]).format("YYYY-MM-DD")
            }
        }
        setData([]);
        setLoading(true);
        let promo: any;
        if (self.only_study_group?.length > 0) {
            if (self.only_study_group.find((group: any) => group.category_id === self?.category_id) !== undefined) {
                http.post(`/check-promo-status/${self.id}/${self.only_study_group.find((group: any) => group.category_id === self?.category_id)?.id}`).then((response: any) => {
                    promo = response.data.data
                    if (response.data.data !== null) {
                        const { data } = response.data;
                        const amount = Object.keys(data).map((key: any) => {
                            return Object.keys(data[key].services).reduce((prev: any, current: any) => {
                                return prev + Number(data[key].services[current]?.discount)
                            }, 0)
                        })[0];
                        const registrationAmount = Object.keys(data).map((key: any) => {
                            return data[key].registration.hasOwnProperty('discount') ? Number(data[key].registration.discount) : 0
                        })[0];
                        const logic = Object.keys(data).map((key: any) => {
                            return data[key].promo.promo_logic;
                        })[0];
                        setPromoAmount((Number(amount) + registrationAmount).toFixed(2))
                    }
                })
            }
        }
        http.get(`/payments`, { params }).then(result => {
            // console.log(promo)
            setData(result.data.data.map((item: any) => {
                let promoEffect = {};
                if (item.promo && item.bonus_payment_id === null) {
                    setPromoDeduction((prev: any) => [...prev, item])
                }
                if (
                    item.bonus_payment_id !== null && item.promo
                    && (!!item.commision
                        || item.study_service !== null
                        || item.extra_pmp !== null
                        || item.extra_medical !== null
                        || item.extra_omniva !== null
                        || item.theory_lesson !== null
                        || item.theory_exam_slot_id !== null
                        || item?.reason?.includes("Registration")
                        || !!item?.individual_driving_registration)
                ) {
                    setAllServicesBonus((prev: any) => [...prev, item])
                }
                const isPromo = promo?.hasOwnProperty(`${item.promo_student_id}`);
                if (promo) {
                    const promoObject = Object.keys(promo).map((key: any) => {
                        return promo[key];
                    })[0];
                    const relatedPromo = promoObject?.promo?.promo_services?.some((el: any) => {
                        return el === item.study_group_service_id || el === 'registration' && item?.reason?.includes("Registration");
                    })
                    if (relatedPromo) {
                        // console.log(relatedPromo)
                        promoEffect = promoObject
                    }
                }

                return {
                    ...item,
                    self_info: self,
                    promo_data: isPromo
                        ? promo[`${item.promo_student_id}`]
                        : {},
                    promo_effect: promoEffect,
                    ladder: result.data.data.find((el: any) => { return item.id === el.old_payment_id && !!el.promo }),
                }
            }).filter((item: any) => {
                return Number(item.amount) !== 0;
            }));
            setServices(result.data.data.map((item: any) => {
                return {
                    ...item,
                    self_info: self
                }
            }).filter((item: any) => {
                return (!!item.commision
                    || item.study_service !== null
                    || item.extra_pmp !== null
                    || item.extra_medical !== null
                    || item.extra_omniva !== null
                    || item.theory_lesson !== null
                    || item.theory_exam_slot_id !== null
                    || item?.reason?.includes("Registration")
                    || !!item?.individual_driving_registration)
                    && Number(item.amount) !== 0
                    && !item.promo
                    || item.student_csdd_test_subscription_id
            }));
            setLoading(false);
        });
    }

    const getRequisites = () => {
        http.get(`global-settings-by-scope/requisites`).then((res: AxiosResponse) => {
            console.log(res.data.data)
            const { data } = res.data;
            setSchool((prev: School): School => {
                const copy = { ...prev };
                Object.keys(copy).forEach(key => {
                    copy[key] = data.find((item: any) => {
                        return item.key === key
                    })?.value ?? null
                })
                return copy
            })
        })
    }

    const changeCreditStatus = (payment: any) => {
        http.get(`/rest/change-credit-payment/${payment}`).then((res: AxiosResponse) => {
            loadPayments();
        })
    }

    useEffect(() => {
        getRequisites();
        toDataURL((`einsteins_cropped.png`), (dataUrl: any) => {
            setImgBase64Service(dataUrl)
        });
        toDataURL((`einsteins.png`), (dataUrl: any) => {
            setImgBase64Driving(dataUrl)
        })
        getSelf();
    }, []);

    useEffect(() => {
        if (Object.keys(self).length > 0) {
            loadPayments();
        }
    }, [self])

    const columns = [
        {
            title: <Space><span>{t('payments.sum')}</span></Space>, //summa need
            width: "10%",
            key: 'sum',
            render: (data: any, all: any) => {
                const extraService = all.extra_pmp !== null
                    ? all.extra_pmp
                    : all.extra_medical !== null
                        ? all.extra_medical
                        : all.extra_omniva !== null
                            ? all.extra_omniva
                            : all.theory_lesson !== null
                                ? all.theory_lesson
                                : undefined
                let showPDF = false;
                const status = !!all.commision
                    || all.student_lesson_id === null
                    && (all.study_group_service_id !== null
                        || extraService !== undefined
                        || all.reason?.includes("Registration")
                        || !!all?.individual_driving_registration)
                    || all.theory_exam_slot_id !== null
                    && !all.promo
                    || all.student_csdd_test_subscription_id
                    ? "service"
                    : "driving"
                // const htmlObject = document.createElement('div');
                // htmlObject.innerHTML = pdfTpl(school, data, status, {});
                const prices = data.amount
                const lessonTime = data.student_lesson !== null ? moment(data.student_lesson.lesson_time_slot.date + " " + data.student_lesson.lesson_time_slot.time_from, "YYYY-MM-DD HH:mm:ss") : "";

                const hasLesson = data.student_lesson_id != null;
                const rekins = hasLesson && !!!all.promo
                    ? moment().isBefore(lessonTime)
                        ? <></>
                        : <>
                            <Button
                                disabled={
                                    (!(data?.student_lesson?.instructor_invoice_id !== null)
                                        && !(data?.student_lesson?.status?.includes("cancelled")
                                            && !!data?.commision))
                                    && moment(data?.student_lesson?.lesson_time_slot?.date).isAfter("2021-10-01")
                                }
                                onClick={() => invoiceSettings(data, "driving", {})}
                            >
                                {t('payments.invoice')}
                            </Button>
                        </>
                    : status === "service" && !!!all.promo
                        ? <>
                            <Button onClick={() => invoiceSettings(data, "service", {})}>{t('payments.service_invoice')}</Button>
                        </>
                        : <></>
                return <Space>
                    <span style={{ fontWeight: "bold" }}>{(data.studentBonus && !!data.promo) ? Number(data.studentBonus.amount) < 0 && Number(data.amount) > 0 ? "-" : "" : ""}{(Number(data.amount) - (data.studentBonus && data.promo === 0 ? Number(data.studentBonus.amount) : 0)).toFixed(2)}{(data.studentBonus && !!data.promo) ? " EIN" : ""}</span>
                    {rekins}
                </Space>;
            }
        },
        {
            title: "PROMO",
            dataIndex: "all",
            key: "promo",
            width: "15%",
            render: (record: any, all: any) => {
                // console.log(all, record)
                return record
                    ? Object.keys(all.promo_data).length > 0
                        ? <b>
                            {(Number(all.promo_data?.promo?.amount) * -1)?.toFixed(2)}&nbsp;
                            {
                                all.promo_data?.promo?.promo_logic === "sum"
                                    ? "EUR"
                                    : "%"
                            }
                        </b>
                        : ""
                    : all.gift
                        ? t('payments.gift_card')
                        : ""
            }
        },
        {
            title: t('payments.payment_date'),
            width: "10%",
            key: 'student',
            render: (data: any) => {
                const date = `${data.ts}`.split(' ')[0];
                return `${date}`;
            }
        },
        {
            title: t('payments.reason'),
            width: "40%",
            key: 'reason',
            render: renderLessonInfo
        },
    ] as any;

    const local_columns = [
        // {
        //   key: "control",
        //   render: (data: any) => {
        //     const hasLesson = data.student_lesson_id != null;

        //     if (!hasLesson) {
        //       return;
        //     }

        //     return <Space>
        //       <Button>Rēķins</Button>
        //     </Space>;
        //   },
        //   width: 10
        // },
    ].concat(columns as any);

    const reqModalLayout =
        // setCreditMode(services.some((item: any) => !!item.credit))
        // const hasCredit = services.some((item: any) => !!item.credit);
        // const hasDeposit = services.some((item: any) => !!!item.credit);
        // const modeError = (hasCredit && hasDeposit);
        <div>
            <h4>{t('payments.please_specify_requisites')}.</h4>
            <Input.TextArea
                value={customRequisites}
                autoSize={{ maxRows: 4, minRows: 4 }}
                onChange={(e: any) => setCustomRequisites(e.target.value)}
            />
            <small>*{t('payments.if_field_is_empty_info')}</small>
            {
                services.some((item: any) => !!item.credit) && services.some((item: any) => !!!item.credit)
                && <div>
                    <p><b>Atlasē ir atrasti priekšapmaksas un pēcapmaksas ieraksti, lūdzu izvēlieties rēķina veidu.</b></p>
                    <Radio.Group
                        options={[{ label: "Pēcapmaksas", value: "credit" }, { label: "Priekšapmaksas", value: "deposit" }]}
                        onChange={(e: any) => setCreditMode(e.target.value === "credit" ? true : false)}
                        // value={creditMode ? "credit" : "deposit"}
                        defaultValue={services.some((item: any) => !!item.credit)}
                    />
                </div>
            }
        </div>


    return <div className="main-content">
        <Modal
            visible={reqModalVisible}
            // onOk={() => {
            //     setReqModalVisible(false);
            //     // console.log(customRequisites, services.map((item: any) => item.id));
            //     if (customRequisites.length > 0) {
            //         http.post(`/payments/update-requisites-bulk`, {
            //             payment_ids: services.map((item: any) => item.id),
            //             payment_requisites: customRequisites
            //         }).then(() => message.success("Rekvizīti ir saglabāti"));
            //     }
            //     return createPdf(services, "service", {}, promoAmount);
            // }}
            onCancel={() => setReqModalVisible(false)}
            footer={<Row justify="end">
                <Button
                    type="primary"
                    disabled={services.some((item: any) => !!item.credit) && services.some((item: any) => !!!item.credit) && creditMode === undefined}
                    onClick={() => {
                        setReqModalVisible(false);
                        // console.log(customRequisites, services.map((item: any) => item.id));
                        if (customRequisites.length > 0) {
                            http.post(`/payments/update-requisites-bulk`, {
                                payment_ids: services.map((item: any) => item.id),
                                payment_requisites: customRequisites
                            }).then(() => message.success(t('payments.requisites_saved')));
                        }
                        invoiceSettings(services, "service", { creditMode }, promoAmount)
                        // createPdf(services, "service", { creditMode }, promoAmount);
                        setCreditMode(undefined);
                    }}
                >
                    Ģenerēt
                </Button>
            </Row>}
        >
            {reqModalLayout}
        </Modal>
        <Row style={{ backgroundColor: "#FFFFFF", marginBottom: 30 }} justify="space-between" align="middle">
            <Link style={{ marginLeft: 10, marginBottom: 10 }} to="/planner/payment-help">
                <Button type="primary">
                    {t('common.replenish')}
                </Button>
            </Link>
            <Col span={24} style={{ marginBottom: 10 }}>
                <Row justify="space-between" align="middle">
                    <Row>
                        <DatePicker.RangePicker
                            value={period}
                            onChange={e => {
                                setPeriod(e)
                            }}
                        />
                        <Button
                            style={{ marginLeft: 10 }}
                            loading={loading}
                            onClick={loadPayments}
                        >
                            Atlasīt
                        </Button>
                    </Row>
                    {
                        data.length > 0
                            ? <Button
                                style={{ margin: 8 }}
                                onClick={() => setReqModalVisible(true)}
                                disabled={services.length === 0}
                            >
                                Rēķins par visiem pakalpojumiem
                            </Button>
                            : <></>
                    }
                </Row>
            </Col>
            <Card style={{ width: "100%" }}>
                <Table
                    columns={local_columns as any}
                    loading={loading}
                    rowKey={record => record.id}
                    rowClassName={(data: any) => {
                        return data.amount > 0 || (data.promo && data.studentBonus)
                            ? data.promo
                                ? "student-payments-row-promo"
                                : "student-payments-row-plus"
                            : data.credit_paid || data.credit
                                ? data.credit_paid ? "student-payments-row-credit-paid" : "student-payments-row-credit"
                                : "student-payments-row-minus"
                    }}
                    pagination={{
                        showSizeChanger: false,
                        size: 'small',
                        showTotal: (total) => <div>{t('payments.total')} {total} {t('payments.records')}</div>,
                        position: ['bottomCenter'],
                        pageSizeOptions: ['15'],
                        defaultPageSize: 15,
                    }}
                    dataSource={data}
                />
            </Card>
        </Row>
    </div >;
}
import axios from "axios";

const localhost = "https://api.einsteins.lv/student-api/";
const studentApiBaseUrl = process.env.REACT_APP_STUDENT_API_BASE_URL
    ? process.env.REACT_APP_STUDENT_API_BASE_URL
    : localhost;

const http = axios.create({
    baseURL: studentApiBaseUrl,
    headers: {
        Accept: "application/json",
        Referer: process.env.REACT_APP_STUDENT_URL,
    },
});

window["http" as any] = http as any;

export { http };
